// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:36:39+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            eb76c0d483e031538e8914ac84b5494e
//    Signature:      eyJpdiI6IldMd1FBNE0rVkJvb3RXeGl5UXFqdUE9PSIsInZhbHVlIjoiUTEybitaK1NvcTlcL2ZcL0pZNUtic3FHa2tWajBoXC9WeXZ1NWRoOWVua3krblFzT2pnbnlGMXA3MUd1VTFNUERzR3d0ZFFhSmt0Y09DM3hxRXgxTkNwd3NpTFE1b1wvYXNKc2hBQkMyVno5dTVXcURjcmdQWmIzUng4am03U1g4eU1mVXJqYTlaV3JIb3A4cUlzZk8rczdRa25VZVZobzB6VXluanJ3RXlBMWtJZHNFWVp5K09vQ253K0wrdXlMSk0yU2tYcHFnMWozMlwvUFpPQ2FxZlBKZDU5eG1aWlNiTllrbVNPRXhcL2ZPT2ZZcmxSVGdUVmZESXdcL2FZUHc3WW9DSUNNMlFcL1lYbjM5RDk1b3NGeVNLU2RxWWkrek92XC92eWx4WFJrZENaTUd1bFwvczRcL0lzdWdRV0dLTHMxRzFTSnFQSnJ5NDB5YzBybmlRZXZBQ2JORlwvS0JnTit5UFpjaUF2dk1vMFNiTWlTVXhGZ1FHXC9RUkN0RWpMaEd0UWNuV0ViTml6MW5zOEptNFVGZjhmblAzUXJyOXc9PSIsIm1hYyI6IjIwZmExMTQ4YTZjNWYyZjdlMTlmYThlN2Q0ZTYyZWU3MjlmYjE4ZDY0ODBlZGM1MjVjNWVkZWJhNjA2OWJjYmIifQ==
class PasswordModal extends DetailsModal {
  constructor() {
    super();

    if (this.querySelector('input[aria-invalid="true"]')) this.open({target: this.querySelector('details')});
  }
}

customElements.define('password-modal', PasswordModal);
